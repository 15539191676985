/* @flow */

export const UnsolicitedRatesheetDefaultType = 'default';

export const UnsolicitedRatesheetBespokeType = 'bespoke';

export type UnsolicitedRatesheetTypes = typeof UnsolicitedRatesheetDefaultType | typeof UnsolicitedRatesheetBespokeType;

type Rate = {
  tenor: string,
  rate: number,
  originalRate?: number,
};

export type UnsolicitedRatesheetRatesheet = {
  updatedAt?: Date | string,
  isCustom?: boolean,
  rates: Rate[],
};

export type IssuerRatesheetSnapshot = {
  shortRating: string,
  longRating: string,
  isOwnAdi: boolean,
  ratesheet: UnsolicitedRatesheetRatesheet,
};

export type UnsolicitedRatesheetTrade = {
  unsolicitedRatesheetEntityId?: string,
  investorUserId?: number,
  rate?: number,
  maturityDate: Date,
  tenor: string,
  interestPaid: string,
  principal: number,
  ratesheetsSnapshot?: IssuerRatesheetSnapshot[],
};

export type UnsolicitedRatesheet = {
  entityId?: string,
  investorTenantId: number,
  bankIssuerId: number,
  expiresAt: string,
  type: UnsolicitedRatesheetTypes,
  bankUserId?: number,
  ratesheet?: UnsolicitedRatesheetRatesheet,
  trades: UnsolicitedRatesheetTrade[],
};

export const customerTypes: UnsolicitedRatesheetTypes[] = [
  UnsolicitedRatesheetDefaultType,
  UnsolicitedRatesheetBespokeType,
];
