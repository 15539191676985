import axios from 'axios';

export const getInvestorsUnsolicitedRatesheetsUrl = '/unsolicited-ratesheets/investors/actionable';

export async function getInvestorUnsolicitedRatesheets(url) {
  const { data } = await axios({
    method: 'get',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return data;
}
