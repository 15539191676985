import React from 'react';

import PropTypes from 'prop-types';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage } from 'react-intl';

import SummaryValueHighlight from '../../../../../../components/holding/summary-value-highlight';
import { Money } from '../../../../../money';
import { UnsolicitedRatesheetSettings } from './UnsolicitedRatesheetSettings';

const buildSummary = (trades) =>
  trades.reduce(
    (tradesSummary, { principal, unsolicitedRatesheetEntityId }) => {
      const isReinvestment = !!unsolicitedRatesheetEntityId;

      isReinvestment ? (tradesSummary.reinvested += principal) : (tradesSummary.lost += principal);
      tradesSummary.count += 1;

      return tradesSummary;
    },
    {
      reinvested: 0,
      lost: 0,
      count: 0,
    },
  );

export const UnsolicitedRatesheetsCustomerHeader = ({ customers, isLoading }) => {
  const tradesSummary = buildSummary(customers?.flatMap(({ trades }) => trades || []) || []);

  return (
    <Column>
      <Row
        element="section"
        aria-label="unsolicited ratesheet header"
        className="bank-summary-header"
        justifyContentEnd
      >
        <Row>
          <SummaryValueHighlight
            isFetching={isLoading}
            middle={<Money value={tradesSummary.reinvested} short fraction={tradesSummary.reinvested > 0 ? 2 : 0} />}
            bottom={<FormattedMessage tagName="span" id="reinvested" />}
          />
          <SummaryValueHighlight
            isFetching={isLoading}
            middle={<Money value={tradesSummary.lost} short fraction={tradesSummary.lost > 0 ? 2 : 0} />}
            bottom={<FormattedMessage tagName="span" id="lost" />}
          />
        </Row>
        <Row className="counters">
          <SummaryValueHighlight
            isFetching={isLoading}
            middle={tradesSummary.count}
            bottom={<FormattedMessage tagName="span" id="tradesCount" />}
          />
        </Row>
      </Row>
      <UnsolicitedRatesheetSettings />
    </Column>
  );
};

UnsolicitedRatesheetsCustomerHeader.propTypes = {
  isLoading: PropTypes.bool,
  customers: PropTypes.arrayOf(PropTypes.shape()),
};
