import './send-quote-drawer.scss';

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Row } from 'react-display-flex';
import { FaChevronLeft, FaQuestion, FaTimes } from 'react-icons/fa';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import useSWR from 'swr';

import {
  fetchRatesheets as fetchRatesheetsAction,
  fetchRatesheetsIfNeeded as fetchRatesheetsIfNeededAction,
  resetUpdate as resetUpdateAction,
  updateRatesheets as updateRatesheetAction,
} from '../../../../actions/ratesheets';
import { getLatestTermDepositRatesheetUpdatedAt, hasLicences, isAdmin } from '../../../../actions/session-selector';
import { getInstrumentCode } from '../../../../api/holding/instrument-codes';
import * as rfqApiClient from '../../../../api/holding/rfq';
import rooms from '../../../../api/socket/rooms';
import {
  Drawer,
  licences as availableLicences,
  Loadable,
  Tooltip,
  Tour,
  tourElements,
} from '../../../../components/common';
import includeSocket from '../../../../components/hoc/include-socket';
import { sameDayAsToday } from '../../../../date';
import { getBankRatesheet } from '../../../../ducks/ratesheet/selectors';
import { showToastMessage } from '../../../toast/toast';
import { SendQuote } from './SendQuote';
import { SendQuoteHistory } from './SendQuoteHistory';
import { UpdateRatesheetDrawer } from './UpdateRatesheet/UpdateRatesheetDrawer';

const defaultSegmentCode = 'LOCALGOV';

const SendQuoteDrawerComponent = ({
  tenant,
  onClose,
  hasDashboardLicence,
  latestTermDepositRatesheetUpdatedAt,
  ratesheets,
  userIsAdmin,
  emit,
  fetchRatesheets,
  fetchRatesheetsIfNeeded,
  updateRatesheets,
  resetUpdate,
  rfq = {},
  intl,
}) => {
  const sendQuotesTourRef = useRef();
  const [thirdDrawerOpen, setThirdDrawerOpen] = useState(false);
  const [selectedMaturityCode, setSelectedMaturityCode] = useState(null);
  const [updateRatesheetSkipped, setUpdateRatesheetSkipped] = useState(false);
  const [forceUpdateRatesheetOpen, setForceUpdateRatesheetOpen] = useState(false);
  const bankRatesheet = useMemo(() => getBankRatesheet({ ratesheets }), [ratesheets]);

  useEffect(() => {
    fetchRatesheetsIfNeeded();
  }, []);

  useEffect(() => {
    if (ratesheets.updateData) {
      ratesheets.updateError && showToastMessage(intl.formatMessage({ id: 'internalError.info' }));

      fetchRatesheets();
      resetUpdate();
      setForceUpdateRatesheetOpen(false);
    }
  }, [ratesheets.updateData, fetchRatesheets, intl, ratesheets.updateError, resetUpdate]);

  const isAutoFillEnabled = ({ settlementDate, maturities }) =>
    hasDashboardLicence &&
    tenant !== 'global' &&
    sameDayAsToday(settlementDate) &&
    !maturities.find(({ code }) => code === 'CUSTOM');

  const getAutoFillRfqRatesheet = ({
    settlementDate,
    maturities,
    instrumentCode: internalInstrumentCode,
    segmentCode,
    rfqStatus,
  }) => {
    if (
      rfqStatus !== 'open' ||
      !latestTermDepositRatesheetUpdatedAt ||
      !isAutoFillEnabled({ settlementDate, maturities })
    ) {
      return null;
    }

    const instrumentCode = getInstrumentCode(internalInstrumentCode);

    const rfqRatesheetBySegmentCode =
      bankRatesheet[instrumentCode] &&
      bankRatesheet[instrumentCode].find(
        ({ available, segmentCode: ratesheetSegmentCode }) => ratesheetSegmentCode === segmentCode && available,
      );

    return rfqRatesheetBySegmentCode;
  };

  const { data: response } = useSWR(
    rfqApiClient.buildRfqsListUrl({ tenant }),
    rfqApiClient.listRfqs({ tenant, params: { uuid: rfq.uuid } }),
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  const isLoading = !response || !response?.list?.some(({ uuid: rfqUuid }) => rfqUuid === rfq.uuid);
  const incomingRfq = (response && response.list.find(({ uuid: rfqUuid }) => rfqUuid === rfq.uuid)) || {};

  const autoFillRfqRatesheet =
    incomingRfq &&
    getAutoFillRfqRatesheet({
      ...incomingRfq,
      segmentCode: defaultSegmentCode,
    });

  const onSkipUpdateRatesheet = () => {
    setUpdateRatesheetSkipped(true);
    setForceUpdateRatesheetOpen(false);
  };

  const isTermDeposit = incomingRfq && incomingRfq.instrumentCode && incomingRfq.instrumentCode === 'TD';

  if (
    forceUpdateRatesheetOpen ||
    (!updateRatesheetSkipped &&
      userIsAdmin &&
      incomingRfq &&
      isTermDeposit &&
      autoFillRfqRatesheet &&
      !sameDayAsToday(moment(autoFillRfqRatesheet.updatedAt)))
  ) {
    return (
      <UpdateRatesheetDrawer
        isOpen
        ratesheets={bankRatesheet}
        updateRatesheets={updateRatesheets}
        segmentCode={defaultSegmentCode}
        instrumentCode={getInstrumentCode(incomingRfq.instrumentCode)}
        onClose={onClose}
        onSkip={onSkipUpdateRatesheet}
      />
    );
  }

  const headerComponent = (
    <Row alignItemsCenter>
      {rfq && (
        <Tooltip id={rfq.status} placement="bottom">
          <span aria-label="rfq status" className={`sell-bond-rfq-status verbose ${rfq.status}`}>
            <FormattedMessage id={rfq.status} />
          </span>
        </Tooltip>
      )}
      <Tooltip id="startTour" placement="bottom">
        <Button
          onClick={() => sendQuotesTourRef.current.onReset()}
          className="reset-tour"
          data-tour={tourElements.sendQuotesReset}
        >
          <FaQuestion />
        </Button>
      </Tooltip>
    </Row>
  );

  return (
    <React.Fragment>
      <Drawer
        titleComponent={
          <h2>
            <FormattedMessage id="quote" />
          </h2>
        }
        className={classNames('light send-quote', {
          first: !isLoading && isTermDeposit,
          second: !isLoading && isTermDeposit && !thirdDrawerOpen,
        })}
        open={!!incomingRfq}
        shouldCloseOnClickOutside={false}
        onClose={onClose}
        showMask={false}
        width="34vw"
      >
        <Loadable isLoading={isLoading} size="sm">
          <SendQuote
            rfq={incomingRfq}
            onClose={onClose}
            hasDashboardLicence={hasDashboardLicence}
            emit={emit}
            isLoading={isLoading}
            setMaturityCode={setSelectedMaturityCode}
            maturityCode={selectedMaturityCode}
            autoFillRfqRatesheet={autoFillRfqRatesheet}
            tenant={tenant}
            onUpdateRatesheetClick={() => setForceUpdateRatesheetOpen(true)}
            isRfqPending={rfq.status === 'pending'}
          />
        </Loadable>
      </Drawer>
      {isTermDeposit && (
        <>
          <Drawer
            open={!isLoading}
            className={classNames('light send-quote history-date', {
              second: !isLoading && thirdDrawerOpen,
              third: !isLoading && !thirdDrawerOpen,
            })}
            width="33vw"
            shouldCloseOnClickOutside={false}
            showMask={false}
            headerComponent={!thirdDrawerOpen && headerComponent}
          >
            <SendQuoteHistory rfq={incomingRfq} isLoading={isLoading} />
          </Drawer>
          <Drawer
            open={thirdDrawerOpen}
            className="light send-quote history-tenor third"
            width="33vw"
            shouldCloseOnClickOutside={false}
            showMask={false}
            headerComponent={thirdDrawerOpen && headerComponent}
          >
            <SendQuoteHistory
              rfq={incomingRfq}
              isLoading={isLoading}
              maturityCode={selectedMaturityCode}
              lastTradesLabelId="historyByTenor"
              lastTradesSegmentLabelId="segmentHistoryByTenor"
              isHistoryByTenor
            />
          </Drawer>
        </>
      )}
      <Tour name="sendQuotes" ref={sendQuotesTourRef} />
      {!isLoading && isTermDeposit && (
        <Tooltip id="showByTenor" placement="top">
          <Button
            className="switcher-button"
            onClick={() => {
              setThirdDrawerOpen(!thirdDrawerOpen);
              window.dispatchEvent(new Event('resize'));
            }}
          >
            {thirdDrawerOpen ? <FaTimes /> : <FaChevronLeft />}
          </Button>
        </Tooltip>
      )}
    </React.Fragment>
  );
};

SendQuoteDrawerComponent.propTypes = {
  rfq: PropTypes.shape().isRequired,
  tenant: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  hasDashboardLicence: PropTypes.bool.isRequired,
  latestTermDepositRatesheetUpdatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  ratesheets: PropTypes.shape(),
  userIsAdmin: PropTypes.bool,
  emit: PropTypes.func,
  fetchRatesheets: PropTypes.func.isRequired,
  fetchRatesheetsIfNeeded: PropTypes.func.isRequired,
  updateRatesheets: PropTypes.func.isRequired,
  resetUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tenant: state.tenant,
  hasDashboardLicence: hasLicences(availableLicences.dashboard)(state),
  latestTermDepositRatesheetUpdatedAt: getLatestTermDepositRatesheetUpdatedAt(state),
  ratesheets: state.ratesheets,
  userIsAdmin: isAdmin(state),
});

const mapDispatchToProps = {
  fetchRatesheetsIfNeeded: fetchRatesheetsIfNeededAction,
  fetchRatesheets: fetchRatesheetsAction,
  updateRatesheets: updateRatesheetAction,
  resetUpdate: resetUpdateAction,
};

export const SendQuoteDrawer = compose(
  includeSocket({ rooms: [rooms.rfq] }),
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SendQuoteDrawerComponent);
