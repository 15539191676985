import axios from 'axios';

import { endOfDay, startOfDay } from '../../../date';

export const buildGetInvestorsUnsolicitedRatesheetsUrl = ({ tenantsIds, from, to }) => {
  const searchParams = new URLSearchParams([['from', startOfDay(from).toISOString()]]);

  if (to) {
    searchParams.append('to', endOfDay(to).toISOString());
  }

  return ['/unsolicited-ratesheets/investors', searchParams.toString()];
};

export async function getInvestorUnsolicitedRatesheets(url, searchParams) {
  const { data } = await axios({
    method: 'get',
    url: `${url}?${searchParams}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return data;
}
