import './collation-of-ratesheets.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import useSWR, { mutate } from 'swr';

import { setRFQInitialValues } from '../../../../../../actions/holding/rfq/rfq';
import { tradeSources } from '../../../../../../api/holding/codes';
import { buildFilteredTradesUrl } from '../../../../../../api/holding/filtered-trades';
import { getTradesMaturingSummary, getTradesMaturingSummaryUrl } from '../../../../../../api/holding/maturing-summary';
import { acceptBespokeUnsolicitedRatesheet } from '../../../../../../api/unsolicited-ratesheets/investor/accept-bespoke-unsolicited-ratesheet';
import { acceptDefaultUnsolicitedRatesheet } from '../../../../../../api/unsolicited-ratesheets/investor/accept-default-unsolicited-ratesheet';
import {
  getInvestorsUnsolicitedRatesheetsUrl,
  getInvestorUnsolicitedRatesheets,
} from '../../../../../../api/unsolicited-ratesheets/investor/get-investor-actionable-unsolicited-ratesheets';
import { InputSearch, ResultsPresenter } from '../../../../../../components/common';
import { withNavigate } from '../../../../../../components/hoc/with-router-properties';
import { routes } from '../../../../../../routes';
import { getMoneySymbol } from '../../../../../money';
import { MessageType, showResponseErrorMessage, showToastMessage } from '../../../../../toast/toast';
import { columns, presenter } from './collation-of-ratesheets-presenter';
import { CollationOfRatesheetsHeader } from './CollationOfRatesheetsHeader';

const mapStateToProps = (state) => ({
  currency: state.session.user.currency,
});

export const CollationOfRatesheetsComponent = ({ currency, navigate, setInitialValuesInRfq, intl }) => {
  const { data, error } = useSWR(getInvestorsUnsolicitedRatesheetsUrl, getInvestorUnsolicitedRatesheets);
  const { data: maturingSummary, mutate: mutateMaturingSummary } = useSWR(
    getTradesMaturingSummaryUrl,
    getTradesMaturingSummary,
  );

  const [investorUnsolicitedRatesheets, setInvestorUnsolicitedRatesheets] = useState([]);
  const [filter, setFilter] = useState({});
  const [isAccepting, setIsAccepting] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    }

    let nextInvestorUnsolicitedRatesheets = Array.from(data);

    if (filter.issuerName) {
      nextInvestorUnsolicitedRatesheets = nextInvestorUnsolicitedRatesheets.filter(
        ({ issuer }) => !filter.issuerName || issuer?.name.match(new RegExp(filter.issuerName, 'i')),
      );
    }

    setInvestorUnsolicitedRatesheets(nextInvestorUnsolicitedRatesheets);
  }, [data, filter]);

  const filterByIssuerName = (event) => setFilter(() => ({ ...filter, issuerName: event.target.value }));

  const renderMaturitiesFilter = () => (
    <Row className="unsolicited-ratesheet-filter" wrap>
      <Row justifyContentSpaceBetween>
        <div className="customer-filter filter-item">
          <p className="grey-color text-ellipsis">
            <FormattedMessage id="unsolicitedRatesheetBank" />
          </p>
          <InputSearch
            defaultValue={filter.issuerName}
            onChange={filterByIssuerName}
            onClear={() => setFilter(() => ({ ...filter, issuerName: undefined }))}
          />
        </div>
        <Button onClick={() => setFilter({})}>
          <FormattedMessage id="reset" />
        </Button>
      </Row>
    </Row>
  );

  const onCreateRfqClick = (initialValues) => {
    setInitialValuesInRfq({
      initialValues: {
        ...initialValues,
        sourceIssuerId: initialValues.issuerId,
        issuersIds: investorUnsolicitedRatesheets.map(({ issuer }) => issuer.id),
      },
    });

    navigate(routes.holdings.newRfq);
  };

  const onReinvestRfqClick = ({ id, ...initialValues }) => {
    setInitialValuesInRfq({
      initialValues: {
        ...initialValues,
        sourceIssuerId: initialValues.issuerId,
        issuersIds: investorUnsolicitedRatesheets.map(({ issuer }) => issuer.id),
      },
    });

    navigate(routes.holdings.rfq.replace(':id', id));
  };

  const updateHeaderSummary = () =>
    [
      buildFilteredTradesUrl({ source: tradeSources.defaultUnsolicitedRatesheet }),
      buildFilteredTradesUrl({ source: tradeSources.bespokeUnsolicitedRatesheet }),
    ].forEach((urlKey) => {
      mutate(urlKey);
    });

  const onAcceptDefaultRatesheetConfirm = async (values) => {
    try {
      setIsAccepting(true);

      await acceptDefaultUnsolicitedRatesheet(values);

      showToastMessage(intl.formatMessage({ id: 'tradeFromRateCreatedSuccessfully' }), MessageType.SUCCESS);
      updateHeaderSummary();
      mutateMaturingSummary();
    } catch (error) {
      showResponseErrorMessage({ intl, error });
    } finally {
      setIsAccepting(false);
    }
  };

  const onAcceptBespokeRatesheetConfirm = async (values) => {
    try {
      setIsAccepting(true);

      await acceptBespokeUnsolicitedRatesheet(values);

      showToastMessage(intl.formatMessage({ id: 'tradeFromRateCreatedSuccessfully' }), MessageType.SUCCESS);
      updateHeaderSummary();
      mutateMaturingSummary();
    } catch (error) {
      showResponseErrorMessage({ intl, error });
    } finally {
      setIsAccepting(false);
    }
  };

  const currencySymbol = getMoneySymbol({ currency, short: true });
  const presenterBuilderMetadata = {
    isFetching: false,
    noRecords: !investorUnsolicitedRatesheets?.length,
    recordsPresenter: {
      actions: {
        onCreateRfqClick,
        onReinvestRfqClick,
        onAcceptDefaultRatesheetConfirm,
        onAcceptBespokeRatesheetConfirm,
      },
      data: investorUnsolicitedRatesheets,
      presenter,
      columns: columns({ currencySymbol }),
      options: {
        isAccepting,
        maturingSummary,
      },
    },
    filter: {
      component: renderMaturitiesFilter(),
    },
    hasError: !!error,
  };

  return (
    <section
      aria-label="collation of ratesheets section"
      className={classNames('collation-of-ratesheets-container', { 'is-loading': !data })}
    >
      <CollationOfRatesheetsHeader />
      <ResultsPresenter {...presenterBuilderMetadata} />
    </section>
  );
};

CollationOfRatesheetsComponent.propTypes = {
  currency: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  setInitialValuesInRfq: setRFQInitialValues,
};

export const CollationOfRatesheets = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withNavigate,
)(CollationOfRatesheetsComponent);
