/* eslint-disable react/jsx-curly-newline */
import './rfqs-history.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Row as FlexRow } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as dashboardActions from '../../../actions/dashboard';
import * as rfqsHistoryActions from '../../../actions/reports/outgoing-rfqs/rfqs-history';
import { hasRequiredRole } from '../../../actions/session-selector';
import { unsolicitedRatesheetSources } from '../../../api/holding/codes';
import { isBondOrFrn } from '../../../api/holding/instrument-codes';
import blackLogo from '../../../assets/icons/common/logo_black.png';
import { PanelPresenter, Range } from '../../../components/common';
import { renderSelect } from '../../../components/common/dropdown-filter';
import OutgoingQuotesDialog from '../../../components/dashboard/outgoing-quotes-dialog';
import { InvestorUnsolicitedRatesheetsSnapshot } from '../../../components/dashboard/unsolicited-ratesheets-snapshot/InvestorUnsolicitedRatesheetsSnapshot';
import { withNavigate } from '../../../components/hoc/with-router-properties';
import Row from '../../../components/reports/rfqs-history/outgoing/row';
import Table from '../../../components/reports/rfqs-history/outgoing/table';
import { formatToShortDateWithoutTz, today } from '../../../date';
import { getOutgoingRfqHistoryOffers, getOutgoingRfqHistoryRfqs } from '../../../ducks/reports/selectors';
import { routes } from '../../../routes';
import { BuySellBondRfqsCloseDrawer } from '../../rfqs/buy-sell-bond-rfqs/BuySellBondRfqsCloseDrawer';

const rfqsAndOffers = 'rfqsAndOffers';
const rfqs = 'rfq';
const offers = 'offer';

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  licences: state.session.user.licences,
  hasFinanceRole: hasRequiredRole('finance')(state),
  quotes: state.outgoingRfqsReport.quotes,
  outgoingRfqsReport: state.outgoingRfqsReport,
  [rfqsAndOffers]: state.outgoingRfqsReport.outgoingRfqs,
  [offers]: getOutgoingRfqHistoryOffers(state),
  [rfqs]: getOutgoingRfqHistoryRfqs(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    dashboard: bindActionCreators(dashboardActions, dispatch),
    rfqsHistory: bindActionCreators(rfqsHistoryActions, dispatch),
  },
});

const sourceFilterOptions = [
  { value: rfqsAndOffers, label: 'All' },
  { value: rfqs, label: 'RFQ' },
  { value: offers, label: 'Offer' },
];

export class RfqsHistory extends Component {
  constructor(props) {
    super(props);

    const oneMonthAgo = today().add(-1, 'month').toDate();

    this.state = {
      rfqQuotes: {},
      showRfqQuotesDialog: false,
      fromDate: oneMonthAgo,
      toDate: new Date(),
      source: rfqsAndOffers,
      unsolicitedRatesheetTrade: undefined,
    };
  }

  componentDidMount() {
    const { fromDate, toDate } = this.state;
    this.props.actions.rfqsHistory.fetchRfqsHistory({
      fromDate: formatToShortDateWithoutTz(fromDate),
      toDate: formatToShortDateWithoutTz(toDate),
    });
  }

  onCloseRfqQuotesDialog() {
    this.setState({
      showRfqQuotesDialog: false,
      rfqQuotes: {},
    });
  }

  onChangeFromDate = (selectedDate) => {
    this.setState({ fromDate: selectedDate });
  };

  onChangeToDate = (selectedDate) => {
    this.setState({ toDate: selectedDate });
  };

  viewDetails = (rfq) => {
    if (unsolicitedRatesheetSources.includes(rfq.source)) {
      this.setState({ unsolicitedRatesheetTrade: rfq });

      return;
    }

    this.openDetails({
      uuid: rfq.uuid,
      offerId: rfq.offerId,
      instrumentCode: rfq.instrumentCode,
      operation: rfq.operation,
    });
  };

  getRows() {
    const rows = this.props[this.state.source];
    const { hasFinanceRole } = this.props;

    return rows.map((rfq) => {
      const canViewDetails =
        rfq.offerId > 0 ||
        (hasFinanceRole && ['closed', 'cancelled'].includes(rfq.rfqStatus)) ||
        unsolicitedRatesheetSources.includes(rfq.source);

      return (
        <Row
          key={rfq.uuid}
          uuid={rfq.uuid}
          operation={rfq.operation}
          source={rfq.source}
          instrumentCode={rfq.instrumentCode}
          offerId={rfq.offerId}
          date={rfq.createdAt}
          time={rfq.createdAt}
          status={rfq.rfqStatus}
          cancelOption={rfq.cancelOption}
          currency={rfq.currency}
          principal={rfq.principal}
          capitalValue={rfq.capitalValue}
          grossValue={rfq.grossValue}
          assetClass={rfq.instrumentCode}
          winningIssuer={rfq.winningIssuer}
          winningTenor={rfq.winningTenor}
          winningMaturityDate={rfq.winningMaturityDate}
          winningCoupon={rfq.winningCoupon}
          createdBy={rfq.createdBy}
          nominatedBy={rfq.nominatedBy}
          signedOffBy={rfq.signedOffBy}
          acceptedBy={rfq.acceptedBy}
          confirmedBy={rfq.confirmedBy}
          cancelledBy={rfq.cancelledBy}
          viewDetailsAction={() => {
            if (unsolicitedRatesheetSources.includes(rfq.source)) {
              this.setState({ unsolicitedRatesheetTrade: rfq });

              return;
            }

            this.openDetails({
              uuid: rfq.uuid,
              offerId: rfq.offerId,
              instrumentCode: rfq.instrumentCode,
              operation: rfq.operation,
            });
          }}
          canViewDetails={canViewDetails}
          settlementDate={rfq.settlementDate}
        />
      );
    });
  }

  async openDetails({ uuid, offerId, instrumentCode, operation, currency }) {
    if (isBondOrFrn(instrumentCode)) {
      this.setState({ selectedRfq: { uuid, operation, currency } });

      return;
    }

    if (uuid) {
      await this.props.actions.rfqsHistory.fetchQuotes(uuid);
      this.setState({
        rfqQuotes: this.props.quotes,
        showRfqQuotesDialog: true,
      });

      return;
    }

    this.props.navigate(routes.offers.details.replace(':id', offerId));
  }

  renderRfqQuotesDialog = () => (
    <OutgoingQuotesDialog
      onClose={() => this.onCloseRfqQuotesDialog()}
      show={this.state.showRfqQuotesDialog}
      rfqQuotes={this.state.rfqQuotes}
      isLoading={this.props.outgoingRfqsReport.isFetchingQuotes}
    />
  );

  renderEmptyResults = () => (
    <section className="results-presenter">
      <Col className="text-center no-records-message">
        <img src={blackLogo} alt="logo" />
        <FormattedMessage tagName="span" id="noRecords" />
      </Col>
    </section>
  );

  onFilterChange = (value) => {
    this.setState({ source: value });
  };

  renderToolbar() {
    const { fromDate, toDate, source } = this.state;

    return (
      <FlexRow justifyContentSpaceBetween alignItemsCenter>
        <div className="toolbar-container">
          {renderSelect({
            name: 'status',
            label: 'reports.rfqsHistory.source',
            onDropdownFilterChange: (_filterName, value) => this.onFilterChange(value),
            options: sourceFilterOptions,
            defaultValue: source,
          })}
          <div className="toolbar-item">
            <Range from={fromDate} to={toDate} onChangeFrom={this.onChangeFromDate} onChangeTo={this.onChangeToDate} />
          </div>
          <button
            type="button"
            className="btn btn-default"
            onClick={() =>
              this.props.actions.rfqsHistory.fetchRfqsHistory({
                fromDate: formatToShortDateWithoutTz(fromDate),
                toDate: formatToShortDateWithoutTz(toDate),
              })
            }
          >
            <FormattedMessage tagName="span" id="reports.rfqsHistory.filterByDateButton" />
          </button>
        </div>

        <button
          type="button"
          className="btn btn-default"
          onClick={() =>
            this.props.actions.rfqsHistory.fetchRfqsHistoryCsv({
              fromDate: formatToShortDateWithoutTz(fromDate),
              toDate: formatToShortDateWithoutTz(toDate),
            })
          }
        >
          <FormattedMessage tagName="span" id="reports.rfqsHistory.downloadCsvButton" />
        </button>
      </FlexRow>
    );
  }

  hasBondOrFrnRfqs() {
    const rows = this.props[this.state.source];

    return rows.some(({ instrumentCode }) => isBondOrFrn(instrumentCode));
  }

  render() {
    const { unsolicitedRatesheetTrade, fromDate, toDate } = this.state;

    return (
      <div className="rfqs-history-container">
        <FlexRow className="reports-tab-container-header">
          <FormattedMessage tagName="span" id="selectPeriod" />
        </FlexRow>
        <div className="rfqs-history-panels">
          <PanelPresenter>{this.renderToolbar()}</PanelPresenter>
          <PanelPresenter>
            <Table
              isLoading={this.props.outgoingRfqsReport.isFetching}
              rows={this.getRows()}
              hasBondOrFrnRfqs={this.hasBondOrFrnRfqs()}
            />
          </PanelPresenter>
          {!this.props.outgoingRfqsReport.outgoingRfqs && this.renderEmptyResults()}
        </div>
        {this.renderRfqQuotesDialog()}
        {unsolicitedRatesheetTrade && (
          <InvestorUnsolicitedRatesheetsSnapshot
            onClose={() => this.setState({ unsolicitedRatesheetTrade: null })}
            show={!!unsolicitedRatesheetTrade}
            sourceEntityId={unsolicitedRatesheetTrade.sourceEntityId}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}
        <BuySellBondRfqsCloseDrawer
          rfq={this.state.selectedRfq}
          onCloseDrawer={() => {
            this.setState({ selectedRfq: null });
          }}
          currency={this.state.selectedRfq && this.state.selectedRfq.currency}
        />
      </div>
    );
  }
}

RfqsHistory.propTypes = {
  outgoingRfqsReport: PropTypes.shape().isRequired,
  hasFinanceRole: PropTypes.bool,
  actions: PropTypes.shape().isRequired,
  quotes: PropTypes.shape().isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withNavigate)(RfqsHistory);
