import './unsolicited-ratesheets-snapshot.scss';
import './unsolicited-ratesheets-snapshot-table.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import useSWR from 'swr';

import {
  buildGetInvestorsUnsolicitedRatesheetsUrl,
  getInvestorUnsolicitedRatesheets,
} from '../../../api/unsolicited-ratesheets/investor/get-unsolicited-ratesheets';
import { getFormattedNumberOrEmpty, rateFormat } from '../../../format-numbers';
import Tooltip from '../../common/tooltip';

export const InvestorUnsolicitedRatesheetsSnapshot = ({ fromDate, toDate, sourceEntityId, onClose, show, isBank }) => {
  const [unsolicitedRatesheetTrade, setUnsolicitedRatesheetTrade] = useState();

  const { data: unsolicitedRatesheets } = useSWR(
    buildGetInvestorsUnsolicitedRatesheetsUrl({
      from: fromDate,
      to: toDate,
    }),
    getInvestorUnsolicitedRatesheets,
  );

  useEffect(() => {
    if (!unsolicitedRatesheets || !unsolicitedRatesheets.length) {
      return;
    }

    const nextUnsolicitedRatesheetTrade = unsolicitedRatesheets
      .flatMap(({ trades }) => trades)
      .find(({ entityId }) => entityId === sourceEntityId);

    setUnsolicitedRatesheetTrade(nextUnsolicitedRatesheetTrade);
  }, [unsolicitedRatesheets, sourceEntityId]);

  if (
    !unsolicitedRatesheetTrade ||
    !unsolicitedRatesheetTrade.ratesheetsSnapshot ||
    !unsolicitedRatesheetTrade.ratesheetsSnapshot.length
  ) {
    return null;
  }

  const currentUnsolicitedRatesheet = unsolicitedRatesheets.find(({ trades }) =>
    trades.find((trade) => trade.entityId === sourceEntityId),
  );

  const [firstRatesheetSnapshot] = unsolicitedRatesheetTrade.ratesheetsSnapshot;
  const availableTenors = firstRatesheetSnapshot.ratesheet.rates.map(({ tenor }) => tenor);

  return (
    <div className="static-modal">
      <Modal show={show} className="unsolicited-ratesheets-dialog">
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="unsolicitedRatesheet" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="unsolicited-ratesheets-snapshots-table  table-striped">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="unsolicitedRatesheetBank" />
                </th>
                {availableTenors.map((tenor) => (
                  <th className="text-center" key={tenor}>
                    {tenor}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {unsolicitedRatesheetTrade.ratesheetsSnapshot.map(({ issuer, ratesheet }) => (
                <tr>
                  <Tooltip delayHide={2000} id={`${issuer.id}`} tooltipComponent={issuer.name}>
                    <td class="text-ellipsis">{issuer.name}</td>
                  </Tooltip>
                  {ratesheet.rates.map(({ rate, tenor, isBestQuote }) => (
                    <td className="text-center">
                      <span
                        className={classNames({
                          'best-coupon': isBestQuote,
                          'accepted-quote':
                            currentUnsolicitedRatesheet.bankIssuerId === issuer.id &&
                            unsolicitedRatesheetTrade.tenor === tenor,
                        })}
                      >
                        {getFormattedNumberOrEmpty({ value: rate, format: rateFormat })}
                      </span>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} className="btn-solid-primary btn-save">
            <FormattedMessage id="close" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

InvestorUnsolicitedRatesheetsSnapshot.propTypes = {
  toDate: PropTypes.instanceOf(Date),
  fromDate: PropTypes.instanceOf(Date),
  sourceEntityId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
};
