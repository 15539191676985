import { useState } from 'react';

import classNames from 'classnames';
import { Column, Row } from 'react-display-flex';
import { FormattedNumber } from 'react-intl';

import { NotInterestedInput, PercentageInput, Tooltip } from '../../../../components/common';
import { couponFormat } from '../../../../format-numbers';

export const SendQuoteCouponInput = ({
  quote,
  code,
  codeWithinTenorRange,
  rfq,
  onQuoteChange,
  isRfqClosed,
  isRfqQuoted,
  isQuoteEmpty,
  enableQuoteEditing,
  quoteStatus,
  setMaturityCode,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return isRfqClosed || (isRfqQuoted(rfq) && !enableQuoteEditing) ? (
    <span className={classNames('btn btn-table', quoteStatus)}>
      {!isQuoteEmpty(quote) ? <FormattedNumber {...couponFormat} value={quote} /> : 'Not interested'}
    </span>
  ) : (
    <Row alignItemsCenter>
      <NotInterestedInput
        onNotInterestedEnabled={() => {
          onQuoteChange({ code, value: null });
          setIsDirty(true);
        }}
        className="light"
      >
        {({ notInterested, setNotInterested }) => {
          const isQuoteNotInterested =
            (isRfqQuoted(rfq) && quote === null) || (!isRfqQuoted(rfq) && isDirty && quote === null);

          if (isQuoteNotInterested !== notInterested) {
            setNotInterested(isQuoteNotInterested);
          }

          return (
            <PercentageInput
              data-public
              className={`form-control maturity-${code}`}
              onChange={({ target }) => {
                setIsDirty(true);
                if (!target) {
                  return;
                }

                onQuoteChange({ code, value: target.value });
              }}
              decimalPrecision={4}
              min={-100}
              max={100}
              value={quote !== null && quote !== undefined ? quote.toString() : ''}
              placeholder={notInterested && 'Not interested'}
              onFocus={() => setMaturityCode && setMaturityCode(codeWithinTenorRange || code)}
            />
          );
        }}
      </NotInterestedInput>

      <Column className="input-warning">
        {!isRfqClosed && quote === '0' && (
          <Tooltip id="rfqNotInterestedAlert">
            <i role="img" className="icon fa fa-exclamation-triangle" />
          </Tooltip>
        )}
      </Column>
    </Row>
  );
};
