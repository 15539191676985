import './unsolicited-ratesheets-snapshot-table.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getFormattedNumberOrEmpty, rateFormat } from '../../../format-numbers';

export const UnsolicitedRatesheetsSnapshotTable = ({ acceptedTenor, ratesheetsSnapshot }) => {
  if (!ratesheetsSnapshot) {
    return null;
  }

  const [firstRatesheetSnapshot] = ratesheetsSnapshot;
  const availableTenors = firstRatesheetSnapshot.ratesheet.rates.map(({ tenor }) => tenor);

  return (
    <table className="unsolicited-ratesheets-snapshots-table table-striped">
      <thead>
        <tr>
          <th colSpan={2}>
            <FormattedMessage id="unsolicitedRatesheetBank" />
          </th>
          {availableTenors.map((tenor) => (
            <th className="text-center" key={tenor}>
              {tenor}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {ratesheetsSnapshot
          .sort(({ isOwnAdi: leftIsOwnAdi }, { isOwnAdi: rightIsOwnAdi }) => rightIsOwnAdi - leftIsOwnAdi)
          .map(({ isOwnAdi, shortRating, longRating, ratesheet }) => (
            <tr className={`${isOwnAdi ? 'your-adi-row' : ''}`}>
              {isOwnAdi ? (
                <td colSpan={2}>
                  <FormattedMessage id="dashboard.dialog.thisAdi" />
                </td>
              ) : (
                <React.Fragment>
                  <td>
                    <label>
                      {longRating} <FormattedMessage id="unsolicitedRatesheetLong" />
                    </label>
                  </td>
                  <td>
                    <label>
                      {shortRating} <FormattedMessage id="unsolicitedRatesheetShort" />
                    </label>
                  </td>
                </React.Fragment>
              )}
              {ratesheet.rates.map(({ rate, tenor, isBestQuote, isWinner }) => (
                <td className="text-center">
                  <span
                    className={classNames({
                      'best-coupon': isBestQuote,
                      'accepted-quote': isWinner,
                    })}
                  >
                    {getFormattedNumberOrEmpty({ value: rate, format: rateFormat })}
                  </span>
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

UnsolicitedRatesheetsSnapshotTable.propTypes = {
  acceptedTenor: PropTypes.number.isRequired,
  ratesheetSnapshot: PropTypes.arrayOf(PropTypes.shape()),
};
