import './unsolicited-ratesheets-snapshot.scss';

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import useSWR from 'swr';

import {
  buildGetIssuersUnsolicitedRatesheetsUrl,
  getIssuerUnsolicitedRatesheets,
} from '../../../api/unsolicited-ratesheets/issuer/get-issuer-unsolicited-ratesheets';
import { UnsolicitedRatesheetsSnapshotTable } from './UnsolicitedRatesheetsSnapshotTable';

export const UnsolicitedRatesheetsSnapshot = ({
  investorTenantId,
  fromDate,
  toDate,
  sourceEntityId,
  onClose,
  show,
}) => {
  const [unsolicitedRatesheetTrade, setUnsolicitedRatesheetTrade] = useState();

  const { data: unsolicitedRatesheets } = useSWR(
    buildGetIssuersUnsolicitedRatesheetsUrl({
      tenantsIds: [investorTenantId],
      from: fromDate,
      to: toDate,
      includeParticipated: true,
    }),
    getIssuerUnsolicitedRatesheets,
  );

  useEffect(() => {
    if (!unsolicitedRatesheets || !unsolicitedRatesheets.length) {
      return;
    }

    const nextUnsolicitedRatesheetTrade = unsolicitedRatesheets
      .flatMap(({ trades }) => trades)
      .find(({ entityId }) => entityId === sourceEntityId);

    setUnsolicitedRatesheetTrade(nextUnsolicitedRatesheetTrade);
  }, [unsolicitedRatesheets, sourceEntityId]);

  if (
    !unsolicitedRatesheetTrade ||
    !unsolicitedRatesheetTrade.ratesheetsSnapshot ||
    !unsolicitedRatesheetTrade.ratesheetsSnapshot.length
  ) {
    return null;
  }

  return (
    <div className="static-modal">
      <Modal show={show} className="unsolicited-ratesheets-dialog">
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="unsolicitedRatesheet" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UnsolicitedRatesheetsSnapshotTable
            acceptedTenor={unsolicitedRatesheetTrade.tenor}
            ratesheetsSnapshot={unsolicitedRatesheetTrade.ratesheetsSnapshot}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} className="btn-solid-primary btn-save">
            <FormattedMessage id="close" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

UnsolicitedRatesheetsSnapshot.propTypes = {
  investorTenantId: PropTypes.number.isRequired,
  toDate: PropTypes.instanceOf(Date),
  fromDate: PropTypes.instanceOf(Date),
  sourceEntityId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
};
