import React from 'react';

import classNames from 'classnames';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { Checkbox } from '../../../../../../components/common';
import { Money } from '../../../../../money';

export const columns = ({ currencySymbol, isSelectAllChecked }) => ({
  select: { label: null, selectAll: true, isSelectAllChecked },
  customer: { label: 'dashboard.customer', sort: 'customerTenant.name' },
  customerType: { label: 'customerType' },
  segment: { label: 'segmentCode' },
  lost: { label: 'lost', className: 'text-right with-principal' },
  reinvested: { label: 'reinvested', className: 'text-right with-principal' },
  status: { label: 'status' },
  actions: { label: null },
});

const buildStatusColumn = ({ data, createdTrades }) => {
  if (createdTrades && createdTrades.length) {
    return {
      id: 'unsolicited-ratesheet-status-created-trades',
      values: {
        count: createdTrades?.length,
      },
    };
  }

  if (data.unsolicitedRatesheetType === 'bespoke' && data.unsolicitedRatesheetEntityId && !data.trades?.length) {
    return {
      id: 'bespoke-sent',
    };
  }

  return {
    id: 'nothing-done',
  };
};

export const presenter = (data, index, actions, { selectedCustomers, isLoadingTrades }) => {
  const lostTrades = data.trades?.filter(({ unsolicitedRatesheetEntityId }) => !unsolicitedRatesheetEntityId);
  const createdTrades = data.trades?.filter(({ unsolicitedRatesheetEntityId }) => unsolicitedRatesheetEntityId);

  const status = buildStatusColumn({ data, createdTrades });

  return (
    <tr
      onDoubleClick={() => {
        actions.onDetailsClick(data);
      }}
      key={index}
      className={classNames({ 'is-loading-trades': isLoadingTrades })}
    >
      <td className="select-checkbox">
        <Checkbox
          name={data.investorTenantId}
          checked={selectedCustomers.includes(data.investorTenantId)}
          onChange={actions.onCustomerCheckboxChange}
        />
      </td>
      <td>{data.name}</td>
      <td>{data.customerType && <FormattedMessage id={data.customerType} />}</td>
      <td>{data.segmentCode}</td>
      <td className="text-right with-principal">
        {lostTrades?.length ? (
          <React.Fragment>
            <Money value={lostTrades?.reduce((reinvested, { principal }) => reinvested + principal, 0)} />
            {` (${lostTrades.length})`}
          </React.Fragment>
        ) : null}
      </td>
      <td className="text-right with-principal">
        {createdTrades?.length ? (
          <React.Fragment>
            <Money value={createdTrades?.reduce((reinvested, { principal }) => reinvested + principal, 0)} />
            {` (${createdTrades.length})`}
          </React.Fragment>
        ) : null}
      </td>
      <td className="status">
        <span data-testid="status" className={classNames('unsolicited-ratesheet-status', status.id)}>
          <FormattedMessage tagName="span" {...status} />
        </span>
      </td>
      <td className="actions">
        <DropdownButton
          id={`unsolicited-ratesheet-action-${index}`}
          pullRight
          noCaret
          data-testid="unsolicited-ratesheet-dropdown-action"
          title={<FaEllipsisV />}
        >
          <MenuItem onClick={() => actions.onDetailsClick(data)} data-testid="reinvest-button" eventkey={1}>
            <FormattedMessage tagName="span" id="details" />
          </MenuItem>
          <MenuItem
            disabled={selectedCustomers.length > 1}
            onClick={() => actions.onContextSendBespokeRatesheets(data)}
            data-testid="reinvest-button"
            eventkey={1}
          >
            <FormattedMessage tagName="span" id="sendBespokeRatesheets" />
          </MenuItem>
        </DropdownButton>
      </td>
    </tr>
  );
};
